import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "#1d2228", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "white",
    setHorizontalLayout: false, // Horizontal layout
    access: '',
    refresh: '',
    token: '',
    isAuthenticated: false,
    /*meta: {
      requiresCliente: false,
      requiresOperador: false,
      requiresAdmin: false,
    },*/
    permissions: '',
    //id_user: '',
    //username: '',
    //useremail: '',

  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    initializeStore(state) {
      if (localStorage.getItem("access")){
        state.access = localStorage.getItem("access")
        state.refresh = localStorage.getItem("refresh")
      }
      else {
        state.access = ''
        state.refresh = ''
      }
      //Seguridad con Tokens
      if (localStorage.getItem("token")){
        state.token = localStorage.getItem("token")
        state.isAuthenticated = true
        state.permissions = localStorage.getItem("permissions")
        /*if (state.permissions == "CL"){
          state.meta.requiresCliente = true
        }
        else if (state.permissions == "OP"){
          state.meta.requiresOperador = true
        }
        else if (state.permissions == "ADMIN"){
          state.meta.requiresAdmin = true
        }
        else {
          state.meta.requiresCliente = false
          state.meta.requiresOperador = false
          state.meta.requiresAdmin = false
        }*/
        console.log('autenticado')
        //console.log(state.meta)
      }
      else {
        state.token = ''
        state.isAuthenticated = false
        //state.meta.requiresCliente = false
        //state.meta.requiresOperador = false
        //state.meta.requiresAdmin = false
        state.permissions = ''
        console.log('No autenticado')
        //console.log(state.meta)
      }
      console.log(state)
    },
    setAccess(state, access){
      state.access = access
    },
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    setToken(state, token){
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state){
      state.token = ''
      state.isAuthenticated = false
    },
    setPermissions(state, permissions){
      state.permissions = permissions
      /*state.id_user = id_user
      state.username = username
      state.useremail = useremail*/
      state.isAuthenticated = true
      /*if (permissions == "CL"){
        state.meta.requiresCliente = true
      }
      else if (permissions == "OP"){
        state.meta.requiresOperador = true
      }
      else if (permissions == "ADMIN"){
        state.meta.requiresAdmin = true
      }
      else {
        state.meta.requiresCliente = false
        state.meta.requiresOperador = false
        state.meta.requiresAdmin = false
      }*/
    },


  },
  actions: {},
  getters: {},
});
